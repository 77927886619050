import { useState, useEffect } from 'react';
import styled from 'styled-components';
import NetatmoIcon from 'assets/img/netatmo.png';
import { Switch } from 'antd';
import { Select } from 'antd';
import { useSpring, animated } from 'react-spring';
import { Button } from 'antd';
import UserService from 'services/user.service';

const { Option } = Select;

const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  right: 17px;
  z-index: 0;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 40px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const DeviceItem = styled(animated.div)`
  position: relative;
  width: 330px;
  background-color: white;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  :hover {
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.05);
  }
`;

const Header = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DeviceName = styled.p`
  font-size: 23px;
  margin: 0px;
  font-weight: 600;
  color: #0078d8;
`;

const DeviceImage = styled.img`
  height: 180px;
  width: auto;
  object-fit: contain;
  margin-bottom: 20px;
`;

const ParameterRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 43px;
  align-items: center;
`;

const ParameterName = styled.p`
  margin: 0px;
  width: 80px;
  text-align: center;
`;

const TemperatureOptions: any = [
  { value: 24, label: '24' },
  { value: 25, label: '25' },
  { value: 26, label: '26' },
  { value: 27, label: '27' },
  { value: 28, label: '28' },
  { value: 29, label: '29' },
  { value: 30, label: '30' },
  { value: 31, label: '31' },
  { value: 32, label: '32' },
  { value: 33, label: '33' },
];

const HumidityOptions: any = [
  { value: 60, label: '60' },
  { value: 61, label: '61' },
  { value: 62, label: '62' },
  { value: 63, label: '63' },
  { value: 64, label: '64' },
  { value: 65, label: '65' },
  { value: 66, label: '66' },
  { value: 67, label: '67' },
  { value: 68, label: '68' },
  { value: 69, label: '69' },
  { value: 70, label: '70' },
];

const Co2Options: any = [
  { value: 900, label: '900' },
  { value: 950, label: '950' },
  { value: 1000, label: '1000' },
  { value: 1050, label: '1050' },
  { value: 1100, label: '1100' },
];

export const Automation = ({ mappedDeviceData }: any) => {
  const [allowNotification, setAllowNotification] = useState(true);
  const springStyles = useSpring({ height: allowNotification ? 440 : 250 });
  const [deviceData, setDeviceData] = useState<any>();
  const [automationValue, setAutomationValue] = useState<any>({
    temperature: { active: true, value: 30, comparator: '>' },
    co2: { active: true, value: 1000, comparator: '>' },
    humidity: { active: true, value: 75, comparator: '>' },
  });

  const handleChangeNoti = () => {
    setAllowNotification(!allowNotification);
  };

  const handleChangeValue = (value: string | number, parameter: string, key: string) => {
    setAutomationValue((prev: any) => {
      let tempValue = { ...prev };
      tempValue[parameter][key] = value;
      return tempValue;
    });
  };

  const postAutomation = () => {
    if (mappedDeviceData) {
      Object.keys(automationValue).forEach((parameter: string) => {
        Object.keys(deviceData).forEach((deviceId: string) => {
          UserService.postAutomations(
            allowNotification,
            deviceData[deviceId]['roomName'],
            deviceData[deviceId]['subdeviceName'],
            parameter,
            automationValue[parameter]['comparator'],
            automationValue[parameter]['value'],
            automationValue[parameter]['active']
          );
        });
      });
    }
  };

  useEffect(() => {
    UserService.getDevices()
      .then((res) => {
        let tempDeviceData: any = {};
        res.data.devices.forEach((deviceInfo: any) => {
          let deviceDataObject: any = {};
          Object.keys(deviceInfo['subdevices']).forEach((key: any) => {
            if (deviceInfo['subdevices'][key]['schema'] === 'environment') {
              deviceDataObject['subdeviceName'] = deviceInfo['subdevices'][key]['nickname'];
              deviceDataObject['roomName'] = deviceInfo['room_name'];
            }
          });
          tempDeviceData[deviceInfo['device_id']] = deviceDataObject;
        });
        setDeviceData(tempDeviceData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Container>
        <Wrapper>
          <DeviceItem style={springStyles}>
            <Header>
              <DeviceName>Netatmo</DeviceName>
              <Switch
                checked={allowNotification}
                checkedChildren="Noti"
                unCheckedChildren="Off"
                onChange={handleChangeNoti}
              />
            </Header>
            <DeviceImage src={NetatmoIcon} />
            <ParameterRow>
              <Switch
                checked={automationValue['temperature']['active']}
                onChange={(e: any) => handleChangeValue(e, 'temperature', 'active')}
              />
              <ParameterName>Temperature</ParameterName>
              <Select
                style={{ width: 50 }}
                value={automationValue['temperature']['comparator']}
                onChange={(e: any) => handleChangeValue(e, 'temperature', 'comparator')}
              >
                <Option value=">">{'>'}</Option>
                <Option value="=">{'='}</Option>
              </Select>
              <Select
                style={{ width: 90 }}
                value={automationValue['temperature']['value']}
                onChange={(e: any) => handleChangeValue(e, 'temperature', 'value')}
              >
                {TemperatureOptions.map((item: any) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </ParameterRow>
            <ParameterRow>
              <Switch
                checked={automationValue['humidity']['active']}
                onChange={(e: any) => handleChangeValue(e, 'humidity', 'active')}
              />
              <ParameterName>Humidity</ParameterName>
              <Select
                style={{ width: 50 }}
                value={automationValue['humidity']['comparator']}
                onChange={(e: any) => handleChangeValue(e, 'humidity', 'comparator')}
              >
                <Option value=">">{'>'}</Option>
                <Option value="=">{'='}</Option>
              </Select>
              <Select
                style={{ width: 90 }}
                value={automationValue['humidity']['value']}
                onChange={(e: any) => handleChangeValue(e, 'humidity', 'value')}
              >
                {HumidityOptions.map((item: any) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </ParameterRow>
            <ParameterRow>
              <Switch
                checked={automationValue['co2']['active']}
                onChange={(e: any) => handleChangeValue(e, 'co2', 'active')}
              />
              <ParameterName>CO2</ParameterName>
              <Select
                style={{ width: 50 }}
                value={automationValue['co2']['comparator']}
                onChange={(e: any) => handleChangeValue(e, 'co2', 'comparator')}
              >
                <Option value=">">{'>'}</Option>
                <Option value="=">{'='}</Option>
              </Select>
              <Select
                style={{ width: 90 }}
                value={automationValue['co2']['value']}
                onChange={(e: any) => handleChangeValue(e, 'co2', 'value')}
              >
                {Co2Options.map((item: any) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </ParameterRow>
            <SaveButtonContainer>
              <Button type="primary" onClick={() => postAutomation()} disabled>
                Save
              </Button>
            </SaveButtonContainer>
          </DeviceItem>
        </Wrapper>
      </Container>
    </>
  );
};
