import Modal from 'react-modal';
import Select from 'react-select';
import { Button } from 'antd';
import {
  AdvancedSettingHead,
  AutomationContainer,
  AutomationTopic,
  AutomationSubContainer,
  AutomationColumn,
  Name,
} from './Home.elements';

const customStylesSelect = {
  container: (provided: any) => ({
    ...provided,
    display: 'inline-block',
    width: '200px',
    minHeight: '1px',
    textAlign: 'left',
    border: 'none',
    marginBottom: '20px',
  }),
};

const customStyles: any = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    width: '800px',
    height: 'fit-content',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '30px 20px',
    borderRadius: '10px',
  },
  overlay: {
    background: '#00000090',
  },
};

const options = [
  { value: 'no data', label: 'no data' },
];

export const AdvancedSetting = ({ advancedModalIsOpen, closeAdvancedModal }: any) => {
  return (
    <>
      <Modal
        style={customStyles}
        isOpen={advancedModalIsOpen}
        onRequestClose={closeAdvancedModal}
        contentLabel="Example Modal"
      >
        <AdvancedSettingHead>Advanced setting</AdvancedSettingHead>
        <AutomationContainer>
          <AutomationTopic>Trigger</AutomationTopic>
          <AutomationSubContainer>
            <AutomationColumn>
              <Name>Device</Name>
              <Select options={options} styles={customStylesSelect} />
            </AutomationColumn>
            <AutomationColumn>
              <Name>Condition</Name>
              <Select options={options} styles={customStylesSelect} />
            </AutomationColumn>
            <AutomationColumn>
              <Name>Value</Name>
              <Select options={options} styles={customStylesSelect} />
            </AutomationColumn>
          </AutomationSubContainer>
          <AutomationTopic>Condition</AutomationTopic>
          <AutomationSubContainer>
            <AutomationColumn>
              <Name>Device</Name>
              <Select options={options} styles={customStylesSelect} />
            </AutomationColumn>
            <AutomationColumn>
              <Name>Condition</Name>
              <Select options={options} styles={customStylesSelect} />
            </AutomationColumn>
            <AutomationColumn>
              <Name>Value</Name>
              <Select options={options} styles={customStylesSelect} />
            </AutomationColumn>
          </AutomationSubContainer>
          <AutomationTopic>Action</AutomationTopic>
          <AutomationSubContainer>
            <AutomationColumn>
              <Name>Device</Name>
              <Select options={options} styles={customStylesSelect} />
            </AutomationColumn>
            <AutomationColumn>
              <Name>Condition</Name>
              <Select options={options} styles={customStylesSelect} />
            </AutomationColumn>
            <AutomationColumn>
              <Name>Value</Name>
              <Select options={options} styles={customStylesSelect} />
            </AutomationColumn>
          </AutomationSubContainer>
          <Button type="primary">Save</Button>
        </AutomationContainer>
      </Modal>
    </>
  );
};
