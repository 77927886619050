import { useState } from 'react';
import { LoginWrapper, Logo, FormContainer } from './Login.elements';
import AuthService from 'services/auth.service';
import { Form, Input, Button, Checkbox, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import LogoDaikin from 'assets/img/logo.png';
import { useToasts } from 'react-toast-notifications';
import { Spin } from 'antd';

type formProps = {
  username: string;
  password: string;
};

const Login = ({ setToken }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const handleSubmit = async (e: formProps) => {
    setIsLoading(true);
    AuthService.login(e.username, e.password)
      .then((res: any) => {
        sessionStorage.setItem('accessToken', res.data.token);
        sessionStorage.setItem('role', res.data.role);
        sessionStorage.setItem('hotelName', res.data.hotel.hotel_name);
        sessionStorage.setItem('hotelCode', res.data.hotel.hotel_code);
        sessionStorage.setItem('username', e.username);
        setToken(res.data.token);
        setIsLoading(false);
      })
      .catch((err) => {
        addToast('Bad credentails', { appearance: 'error', autoDismiss: true, });
        setToken(null);
        setIsLoading(false);
      });
  };

  return (
    <LoginWrapper>
      <Logo src={LogoDaikin} alt="logo" />
      <FormContainer>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={handleSubmit}
        >
          <Typography.Title level={5}>Username</Typography.Title>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Typography.Title level={5}>Password</Typography.Title>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              disabled={isLoading}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </FormContainer>
      {isLoading && <Spin />}
    </LoginWrapper>
  );
};

export { Login };
