import RoomStatusNew from 'components/RoomStatusNew';
import { DeviceDataProps } from './homeType';

export const RoomStatusBlock = ({ deviceData, deviceDataFirebase, openModal, userName }: any) => {
  return (
    <>
      {deviceData &&
        deviceDataFirebase &&
        deviceData.map((device: DeviceDataProps) => {
          let checkRoomObject: any = Object.values(deviceDataFirebase[device['room_name']]);
          if (checkRoomObject) {
            let x = device['ui_location'].split(',')[0];
            let y = device['ui_location'].split(',')[1];
            let deviceData = checkRoomObject[0][device.device_id]
            let tempValue =
              deviceData['environment'] && deviceData['environment']['subdev_1']
                ? deviceData['environment']['subdev_1']['temperature']
                : ' - ';
            let humidValue =
              deviceData['environment'] && deviceData['environment']['subdev_1']
                ? deviceData['environment']['subdev_1']['humidity'] ?
                  deviceData['environment']['subdev_1']['humidity_calibration'] ?
                    deviceData['environment']['subdev_1']['humidity'] + deviceData['environment']['subdev_1']['humidity_calibration'] :
                    deviceData['environment']['subdev_1']['humidity']
                  : ' - '
                : ' - ';
            let co2Value =
              deviceData['environment'] && deviceData['environment']['subdev_1']
                ? deviceData['environment']['subdev_1']['co2']
                : ' - ';
            return (
              <RoomStatusNew
                key={device['device_id']}
                x={x}
                y={y}
                roomName={device.room_name}
                displayName={userName === "sfhadmin" ? device.room_name : device.device_name.replace("netatmo ", "").replace(" ", "")}
                tempValue={tempValue}
                humidValue={humidValue}
                co2Value={co2Value}
                openModal={openModal}
                deviceId={device.device_id}
              />
            );
          } else {
            return null;
          }
        })}
    </>
  );
};
