import styled from 'styled-components';
import AlertIconImage from 'assets/img/alert.svg';

const Container = styled.div<any>`
  position: absolute;
  width: fit-content;
  height: fit-content;
  left: ${(props) => props.x}%;
  top: ${(props) => props.y}%;
  cursor: pointer;
`;

const Block = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px 8px;
  border-radius: 5px;
  backdrop-filter: blur(12.4554px);
  background: rgba(0, 0, 0, 0.5);
`;

const Heading = styled.p`
  font-size: 9px;
  font-weight: 600;
  color: white;
  margin: 0px;
  height: 12px;
  margin-bottom: -3px;
`;

const Value = styled.p<any>`
  position: relative;
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.alert ? 'red' : 'white')};
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
`;

const Unit = styled.strong<any>`
  margin: 0px;
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (props.alert ? 'red' : 'white')};
  padding-bottom: 2px;
  padding-left: 5px;
`;

const RoomNameBlock = styled.div`
  position: absolute;
  top: -25px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(331.89deg, #397CDD -52.72%, #93B4F9 406.63%);
  border-radius: 10px;
  border: 0.5px solid #89a9e2;
`;

const RoomNameText = styled.p`
  font-size: 14px;
  height: 20px;
  font-weight: 600;
  color: white;
  margin: 0px 10px 5px 10px;
`;

const AlertIcon = styled.img`
  position: absolute;
  right: -15px;
  width: 25px;
  top: 1px;
  cursor: pointer;
`;

const RoomStatusNew = ({
  x,
  y,
  roomName,
  tempValue,
  tempUnit,
  humidValue,
  humidUnit,
  co2Value,
  co2Unit,
  openModal,
  displayName,
  deviceId
}: any) => {
  return (
    <Container x={x} y={y} onClick={() => openModal(roomName, deviceId)}>
      <Block>
        <RoomNameBlock>
          <RoomNameText>{displayName}</RoomNameText>
        </RoomNameBlock>

        <Heading>Temp</Heading>
        <Value alert={tempValue >= 30}>
          {tempValue ? tempValue : ' - '}
          <Unit alert={tempValue >= 30}>{tempUnit ? tempUnit : '°C'}</Unit>
          {tempValue >= 30 && <AlertIcon src={AlertIconImage} />}
        </Value>

        <Heading>Humid</Heading>
        <Value alert={humidValue >= 75}>
          {humidValue ? humidValue : ' - '}
          <Unit alert={humidValue >= 75}>{humidUnit ? humidUnit : '%'}</Unit>
          {humidValue >= 75 && <AlertIcon src={AlertIconImage} />}
        </Value>

        <Heading>CO2</Heading>
        <Value alert={co2Value >= 1000}>
          {co2Value ? co2Value : ' - '}
          <Unit alert={co2Value >= 1000}>{co2Unit ? co2Unit : 'ppm'}</Unit>
          {co2Value >= 1000 && <AlertIcon src={AlertIconImage} />}
        </Value>
      </Block>
    </Container>
  );
};

export default RoomStatusNew;
