import { useState, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from '../../firebase';
import {
  Wrapper,
  CardBody,
  ContainerBody,
  WrapperBody,
  BodyContainer,
  ImageContainer,
  BottomLogo,
  AutomationHeader,
  AdvancedSettingButton,
  ReportHeader,
} from 'components/Home/Home.elements';
import Sidebar from 'components/Sidebar';
import CardHeader from 'components/CardHeader';
import RoomModal from 'components/RoomModal';
import 'antd/dist/antd.css';
import { Spin } from 'antd';
import { mapValueToKey } from 'utils/mapValueToKey';
import UserService from 'services/user.service';
import { useToasts } from 'react-toast-notifications';

import Floorplanfieldhospital from 'assets/img/floorplanfieldhospital.png';
import ChulaFloorplanfieldhospital from 'assets/img/chulafloorplanfieldhospital.png';
import AbhFloorplanieldhospital from 'assets/img/abhfloorplanfieldhospital.png';
import SaraburiFieldhospital2 from 'assets/img/sfhfloorplanfieldhospital2.png';

import AltoTechLogo from 'assets/img/poweredByAltoTech.svg';
import DaikinLogo from 'assets/img/sponsoredByDaikin.svg';
import { useEffect } from 'react';
import { RoomStatusBlock } from 'components/Home/RoomStatusBlock';
import { DeviceDataProps, DevicesDataProps } from 'components/Home/homeType';
import { AdvancedSetting } from 'components/Home/AdvancedSetting';
import { Automation } from 'components/Home/Automation';
import { Report } from 'components/Home/Report';

export const Row = styled.div`
  margin: 5px;
  display: flex;
  height: auto;
  flex-wrap: wrap;
`;

export const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export type GraphDataProps = {
  [name: string]: {
    title: string;
    data: { x: any; y: any }[];
    unit: string;
  };
};

const EmptyData: GraphDataProps = {
  temperature: {
    title: 'Temperature',
    data: [],
    unit: 'C',
  },
  humidity: {
    title: 'Humidity',
    data: [],
    unit: '%',
  },
  co2: {
    title: 'CO2',
    data: [],
    unit: '%',
  },
  pressure: {
    title: 'Pressure',
    data: [],
    unit: 'mb',
  },
  noise: {
    title: 'Noise',
    data: [],
    unit: 'db',
  },
};

export const hospitalMapper: any = {
  sfhadmin: 'saraburi_field_hospital',
  sfhadmin2: 'saraburi2_field_hospital',
  cufhadmin: 'chula_field_hospital',
  rajavithiadmin: 'rajavithi_field_hospital',
  sirirajadmin: 'siriraj_field_hospital',
  siriraj2admin: 'siriraj2_field_hospital',
  abhfhadmin: 'abhaibhubejhr_field_hospital',
};

const Home = ({ setToken }: any) => {
  let today = new Date();
  let yesterday = new Date(today.setDate(today.getDate() - 1));
  const [startDate, setStartDate] = useState<any>(yesterday);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [tabs, setTabs] = useState('floorplan');
  const firebase = useContext<any>(FirebaseContext);
  const [realtimeData, setRealtimeData] = useState({});
  const [outdoorWeather, setOutdoorWeather] = useState(true);
  const [buildingMainWeather, setbuildingMainWeather] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRoomNo, setSelectedRoomNo] = useState<any>();
  const [selectedDeviceId, setSelectedDeviceId] = useState<any>();
  const [deviceData, setDeviceData] = useState<DevicesDataProps | undefined>();
  const [mappedDeviceData, setMappedDeviceData] = useState<{ [room: string]: DeviceDataProps }>();
  const [deviceDataFirebase, setDeviceDataFirebase] = useState<any>();
  const [graphData, setGraphData] = useState(EmptyData);
  const [graphStatus, setGraphStatus] = useState<boolean>(false);
  const [advancedModalIsOpen, setAdvancedModalIsOpen] = useState<boolean>(false);
  const [roomData, setRoomData] = useState();
  const [floorplanImage, setFloorplanImage] = useState(Floorplanfieldhospital);
  const { addToast } = useToasts();
  const [userName, setUserName] = useState();

  const assignModalData = (data: any, deviceId: string) => {
    let roomObject: any = {};
    roomObject['temperature'] = data
      ? data['environment']
        ? data['environment']['subdev_1']
          ? data['environment']['subdev_1']['temperature']
          : 'No data'
        : 'No data'
      : 'No data';
    roomObject['humidity'] = data
      ? data['environment']
        ? data['environment']['subdev_1']
          ? data['environment']['subdev_1']['humidity']
            ? data['environment']['subdev_1']['humidity_calibration']
              ? data['environment']['subdev_1']['humidity'] +
                data['environment']['subdev_1']['humidity_calibration']
              : data['environment']['subdev_1']['humidity']
            : 'No data'
          : 'No data'
        : 'No data'
      : 'No data';
    roomObject['co2'] = data
      ? data['environment']
        ? data['environment']['subdev_1']
          ? data['environment']['subdev_1']['co2']
            ? data['environment']['subdev_1']['co2']
            : 'No data'
          : 'No data'
        : 'No data'
      : 'No data';
    roomObject['name'] = data
      ? data['device']
        ? data['device']['subdev_0']
          ? data['device']['subdev_0']['module_name']
            ? data['device']['subdev_0']['module_name']
            : 'No data'
          : 'No data'
        : 'No data'
      : 'No data';
    roomObject['status'] = data
      ? data['device']
        ? data['device']['subdev_0']
          ? data['device']['subdev_0']['reachable']
            ? data['device']['subdev_0']['reachable']
            : 'No data'
          : 'No data'
        : 'No data'
      : 'No data';
    roomObject['mac_address'] = deviceId ? deviceId : 'No data';
    roomObject['battery_level'] = data
      ? data['electric']
        ? data['electric']['subdev_3']
          ? data['electric']['subdev_3']['battery_percentage']
          : 'No data'
        : 'No data'
      : 'No data';
    roomObject['humidity_calibration'] = data
      ? data['environment']
        ? data['environment']['subdev_1']
          ? data['environment']['subdev_1']['humidity']
            ? data['environment']['subdev_1']['humidity_calibration']
            : 0
          : 0
        : 0
      : 0;
    return roomObject;
  };

  const handleSetStartDate = (e: any) => {
    setStartDate(e);
    fetchDeviceHistory(selectedDeviceId);
  };

  const handleSetEndDate = (e: any) => {
    setEndDate(e);
    fetchDeviceHistory(selectedDeviceId);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setGraphStatus(false);
    setGraphData(EmptyData);
    fetchModalDataFirebase(firebase, true);
  };

  const openModal = (roomName: string, deviceId: string) => {
    setSelectedRoomNo(roomName);
    if (mappedDeviceData) {
      setSelectedDeviceId({
        deviceId: deviceId,
        deviceName: mappedDeviceData[deviceId]['device_name'],
      });
      fetchDeviceHistory(deviceId, roomName);
      fetchModalDataFirebase(firebase, false, roomName, deviceId);
      setModalIsOpen(true);
    } else {
      addToast('No device found', { appearance: 'error', autoDismiss: true });
      setModalIsOpen(true);
    }
  };

  const closeAdvancedModal = () => {
    setAdvancedModalIsOpen(false);
  };

  const convertDate = (date: Date, time: string) => {
    const convertNumber = (number: number) => {
      if (number < 10) {
        return '0' + number;
      } else {
        return number;
      }
    };
    return (
      date.getFullYear() +
      '-' +
      convertNumber(date.getMonth() + 1) +
      '-' +
      convertNumber(date.getDate()) +
      ' ' +
      time
    );
  };

  const getData = (arrayOfObject: any, key: string, calibration?: number) => {
    let object: any = {};
    let buffer = [];
    for (let i = 0; i < arrayOfObject.length; i++) {
      let buffer_object: any = {};
      buffer_object['x'] = arrayOfObject[i]['timestamp'];
      buffer_object['y'] = arrayOfObject[i][key]
        ? calibration
          ? (Number(arrayOfObject[i][key]) + calibration).toFixed(2)
          : arrayOfObject[i][key].toFixed(2)
        : 0;
      buffer.push(buffer_object);
    }
    object['id'] = key;
    object['data'] = buffer;
    return Array.of(object);
  };

  const fetchDeviceHistory = async (deviceId: string, roomName?: string) => {
    let username: any = sessionStorage.getItem('username');
    const roomStatus = `/hospital/${hospitalMapper[username]}/building_main/${roomName}/iot_devices/${deviceId}`;
    let humidityCalibration = 0;
    if (firebase) {
      firebase.db.ref(roomStatus).once('value', function (snap: any) {
        let capt = snap.val();
        if (deviceId && capt && capt.environment.subdev_1.humidity_calibration) {
          humidityCalibration = capt.environment.subdev_1.humidity_calibration;
        }
        UserService.getDeviceHistory(
          convertDate(startDate, '00:00'),
          convertDate(endDate, '23:30'),
          deviceId,
          1,
          false,
          30
        )
          .then((res) => {
            let data = res.data.results;
            setGraphData(() => {
              let grahpObject: any = EmptyData;
              grahpObject['temperature']['data'] = getData(data, 'temperature');
              grahpObject['humidity']['data'] = getData(data, 'humidity', humidityCalibration);
              grahpObject['co2']['data'] = getData(data, 'co2');
              grahpObject['pressure']['data'] = getData(data, 'pressure');
              grahpObject['noise']['data'] = getData(data, 'noise');
              return grahpObject;
            });
            setGraphStatus(true);
          })
          .catch((err) => {
            addToast('Cannot fetch data from the server.', {
              appearance: 'error',
              autoDismiss: true,
            });
            setGraphStatus(true);
          });
      });
    }
  };

  async function FetchDataFirebase(firebase: any, didMounted: any, hospitalName: any) {
    const outdoorWeather = `/hospital/${hospitalName}/outdoor_weather`;
    const buildingMain = `/hospital/${hospitalName}/building_main`;
    if (didMounted) {
      await firebase.db.ref(outdoorWeather).off('value');
      await firebase.db.ref(buildingMain).on('value');
    } else {
      await firebase.db.ref(outdoorWeather).on(
        'value',
        function (snap: any) {
          let capt = snap.val();
          setRealtimeData(capt);
          setOutdoorWeather(false);
        },
        (error: any) => {
          console.error(error);
        }
      );

      await firebase.db.ref(buildingMain).on('value', function (snap: any) {
        let capt = snap.val();
        setDeviceDataFirebase(capt);
        setbuildingMainWeather(false);
      });
    }
  }

  async function fetchModalDataFirebase(
    firebase: any,
    didMounted: any,
    selectedRoomNo?: string,
    deviceId?: string
  ) {
    let username: any = sessionStorage.getItem('username');
    const roomStatus = `/hospital/${hospitalMapper[username]}/building_main/${selectedRoomNo}/iot_devices/${deviceId}`;
    if (didMounted) {
      await firebase.db.ref(roomStatus).off('value');
    } else {
      await firebase.db.ref(roomStatus).on('value', function (snap: any) {
        let capt = snap.val();
        if (deviceId) {
          setRoomData(assignModalData(capt, deviceId));
        } else {
          addToast('No device found', { appearance: 'error', autoDismiss: true });
        }
      });
    }
  }

  useMemo(() => {
    let username: any = sessionStorage.getItem('username');
    setUserName(username);
    if (firebase) {
      FetchDataFirebase(firebase, false, hospitalMapper[username]);
    }
    return () => FetchDataFirebase(firebase, true, '');
  }, [firebase]);

  useEffect(() => {
    let userName: any = sessionStorage.getItem('username');
    if (userName === 'sfhadmin') {
      setFloorplanImage(Floorplanfieldhospital);
    } else if (userName === 'abhfhadmin') {
      setFloorplanImage(AbhFloorplanieldhospital);
    } else if (userName === 'sfhadmin2') {
      setFloorplanImage(SaraburiFieldhospital2);
    } else {
      setFloorplanImage(ChulaFloorplanfieldhospital);
    }
    UserService.getDevices()
      .then((res) => {
        setDeviceData(res.data.devices);
        setMappedDeviceData(mapValueToKey(res.data.devices, 'device_id'));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Wrapper>
      <CardBody>
        {outdoorWeather && buildingMainWeather ? (
          <>
            <SpinnerContainer>
              <Spin
                tip="Loading..."
                style={{ marginTop: '100px', fontSize: '22px', color: '#0c4271' }}
              />
            </SpinnerContainer>
          </>
        ) : (
          <>
            <Sidebar setToken={setToken} setTabs={setTabs} tabs={tabs} />
            {/* <Notification alert={false} /> */}
            {tabs === 'floorplan' && (
              <ContainerBody>
                <WrapperBody>
                  <CardHeader realtimeData={realtimeData} />
                  <CardBody>
                    <BodyContainer>
                      <ImageContainer>
                        <img src={floorplanImage} alt="floorplan" style={{ width: '100%' }} />
                        <RoomStatusBlock
                          deviceData={deviceData}
                          deviceDataFirebase={deviceDataFirebase}
                          openModal={openModal}
                          userName={userName}
                        />
                      </ImageContainer>
                    </BodyContainer>
                  </CardBody>
                  <ImageContainer style={{ width: '100%', marginTop: '-20px' }}>
                    <BottomLogo src={AltoTechLogo} alt="altotech" />
                    <BottomLogo src={DaikinLogo} alt="daikintech" />
                  </ImageContainer>
                  {roomData && (
                    <RoomModal
                      modalIsOpen={modalIsOpen}
                      closeModal={closeModal}
                      selectedRoomNo={selectedRoomNo}
                      selectedDeviceId={selectedDeviceId}
                      startDate={startDate}
                      handleSetStartDate={handleSetStartDate}
                      endDate={endDate}
                      handleSetEndDate={handleSetEndDate}
                      graphData={graphData}
                      graphStatus={graphStatus}
                      roomData={roomData}
                    />
                  )}
                </WrapperBody>
              </ContainerBody>
            )}
            {tabs === 'automation' && (
              <ContainerBody style={{ paddingTop: '30px' }}>
                <AutomationHeader>
                  Automation
                  <AdvancedSettingButton
                    onClick={() => setAdvancedModalIsOpen(!advancedModalIsOpen)}
                  >
                    Advanced setting
                  </AdvancedSettingButton>
                </AutomationHeader>
                <Automation mappedDeviceData={mappedDeviceData} />
                <AdvancedSetting
                  advancedModalIsOpen={advancedModalIsOpen}
                  closeAdvancedModal={closeAdvancedModal}
                />
              </ContainerBody>
            )}
            {tabs === 'report' && (
              <>
                <ContainerBody style={{ paddingTop: '30px', overflow: 'scroll' }}>
                  <ReportHeader>Report</ReportHeader>
                  <Report />
                </ContainerBody>
              </>
            )}
          </>
        )}
      </CardBody>
    </Wrapper>
  );
};
export { Home };
