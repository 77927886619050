import { useEffect, useState } from "react"
import styled from "styled-components"
import HospitalIcon from "assets/common/hospital.png"

const Container = styled.div`
  width: 200px;
  height: 230px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items: center;
  padding: 20px;
  background: white;
  box-shadow: 0px 4px 30px rgba(194, 194, 194, 0.2);
  backdrop-filter: blur(400px);
  overflow-wrap: normal;
  border-radius: 15px;
  margin-bottom: 10px;
  padding-top: 90px;
  padding-bottom: 0px;
  @media only screen and (max-width: 1201px) {
    height: 170px;
    margin-bottom: 0px;
    padding-bottom: 15px;
  }
`

const LocationName = styled.h4`
  font-size: 16px;
  color: #0E7EE4B2;
  margin:0px;
  overflow-wrap: normal;
  font-weight: bold;
  width: 100%;
  white-space: normal;
  margin-top: 15px;
  margin-bottom: 5px;
  line-height: 20px !important;
`

const ProvinceName = styled.p`
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  color: #454353;
  width: 100%;
`

const Image = styled.img`
  width: 280px;
  position: absolute;
  top: -40px;
  z-index: 99999;
  @media only screen and (max-width: 1201px) {
    top: -30px;
    width: 200px;
  }
`

const Mapper: any = {
  saraburi_field_hospital: "Saraburi Field Hospital",
  saraburi2_field_hospital: "Saraburi Field Hospital 2",
  chula_field_hospital: "Chula Field Hospital",
  rajavithi_field_hospital: "Rajavithi Field Hospital",
  siriraj_field_hospital: 'Siriraj Field Hospital',
  siriraj2_field_hospital: 'Siriraj2 Field Hospital',
  abhaibhubejhr_field_hospital: 'Abhaibhubejhr Field Hospital',
}

export const Location = () => {
  const [hotelName, setHotelName] = useState()
  useEffect(() => {
    let tempHotelName: any = sessionStorage.getItem("hotelName")
    setHotelName(Mapper[tempHotelName])
  }, [])
  return (
    <>
      <Container>
        <Image src={HospitalIcon} alt="hospital" />
        <LocationName>
          {hotelName && hotelName}
        </LocationName>
        <ProvinceName>
          Bangkok
        </ProvinceName>
      </Container>
    </>
  )
}