import styled from 'styled-components';
import SunRiseIcon from 'assets/img/sunrise.svg';
import SunSetIcon from 'assets/img/sunset.svg';
import TemperatureIcon from 'assets/img/temperature.svg';
import HumidityIcon from 'assets/img/humidity.svg';
import ConditionIcon from 'assets/img/condition.svg';
import PressureIcon from 'assets/img/pressure.svg';
import DewPointIcon from 'assets/img/dewpoint.svg';
import WindDirectionIcon from 'assets/img/winddirection.svg';
import WindSpeedIcon from 'assets/img/windspeed.svg';

const Container = styled.div`
  height: fit-content;
  padding: 20px 12px 0px 20px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
`;

const OutdoorWeatherHeading = styled.h2`
  width: 100%;
  text-align: flex-start;
  font-size: 30px;
  margin: 0px 0px 15px 0px;
  font-weight: 600;
  color: #086BBA;

  @media only screen and (max-width: 1201px) {
    font-size: 16px;
    color: #086BBA;
    margin-bottom: 10px; 
    margin-left: 5px
  }
`

const Grid = styled.div`
  display: flex;
  flex-direction: row;
`;

const BlockHeading = styled.h2`
  width: 100%;
  text-align: flex-start;
  font-size: 20px;
  margin: 0px 0px 15px 0px;
  font-weight: 600;
  @media only screen and (max-width: 1201px) {
    font-size: 16px;
  }
`;

const SubBlockHeading = styled.div`
  position: absolute;
  width: 200%;
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  margin-bottom: 25px;
  color: #0E7EE4;
  flex-wrap: wrap;
  white-space: normal;
  top: 0px;
  line-height: 1rem;
  margin-top: 10px;
  @media only screen and (max-width: 1201px) {
    margin-top: 0px;
    font-size: 12px;
    width: 70%;
  }
`;

const MainSubBlockHeading = styled.div`
  width: 200%;
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  margin-bottom: 25px;
  color: #0E7EE4;
  flex-wrap: wrap;
  white-space: normal;
  top: 0px;
  line-height: 1rem;
  margin-top: 10px;
  @media only screen and (max-width: 1201px) {
    margin-top: -15px !important;
    font-size: 12px;
    width: 100%;
  }
`

const SubBlockText = styled.p`
  margin: 0px;
  font-size: 25px;
  display: flex;
  align-items: flex-end;
  font-weight: 600;
  margin-top: 35px;
  @media only screen and (max-width: 1201px) {
    font-size: 17px !important;

  }
`;

const MainBlockText = styled.p`
  margin: 0px;
  font-size: 55px;
  display: flex;
  align-items: flex-end;
  font-weight: 600;
  margin-top: 20px;
  @media only screen and (max-width: 1201px) {
    font-size: 33px !important;
    margin-top: 40px;
  }
`;

const SubBlockUnit = styled.strong`
  margin: 0px;
  font-size: 15px;
  width: fit-content;
  padding-bottom: 3px;
  font-weight: 400;
  margin-left: 7px;
`;

const MainBlockUnit = styled.strong`
  margin: 0px;
  font-size: 30px;
  width: fit-content;
  padding-bottom: 3px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-left: 5px;
  @media only screen and (max-width: 1201px) {
    font-size: 25px;
    margin-left: 0px;
  }
`;

const BlockBody = styled.div<any>`
  width: ${(props) => props.width}%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const BlockContainer = styled.div`
  width: 25%;
  height: 90px;
  display: flex;
  margin-bottom: 0px;
  overflow: hidden;
  padding-bottom: 10px;
  padding-left: 3px;
`;

const Block = styled.div<any>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-top: 0px;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 10px;
  margin-right: 10px;
  overflow: hidden;
  background: linear-gradient(13.56deg, #FFFFFF -37.36%, rgba(202, 223, 253, 0.3) 495.53%);
  box-shadow: 0.505948px 1.51784px 10.119px rgba(196, 196, 196, 0.3);
  border: 0.5 solid white;
  @media only screen and (max-width: 1201px) {
    padding-left: 10px;
    border: 1px solid #dbdbdb;
  }
`;

const MainBlock = styled.div<any>`
  position: relative;
  width: 100%;
  height: calc(100% + 10px);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-left: 15px;
  padding-top: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  background: linear-gradient(318.01deg, rgba(236, 247, 253, 0.6) 13.42%, rgba(245, 245, 245, 0.6) 167.84%);
  box-shadow: 4.16251px 3.48752px 10.2502px rgba(196, 196, 196, 0.3);
  @media only screen and (max-width: 1201px) {
    padding-left: 10px;
    border: 1px solid #e2e2e2;
  }
`;

const TextBox = styled.div`
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
`;

const MainTextBox = styled.div`
  height: 70px;
  margin-top: 10px;
  @media only screen and (max-width: 1201px) {
    height: 70px;
    margin-top: 5px;
  }
`;

const Icon = styled.img`
  width: 40px;
  margin-top: 10px;
  margin-right: 10px;
  @media only screen and (max-width: 1201px) {
    width: 30px;
    margin-top: 0px;
    margin-right: 0px;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 7px;
  right: 5px;
  height: 34px;
  width: 34px;
  border-radius: 17px;
  /* background: linear-gradient(318.01deg, #E1EAFB 13.42%, #C0E8FF 167.84%); */
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MainIcon = styled.img`
  position: absolute;
  right: 0px;
  top: 7px;
  width: 45px;
  @media only screen and (max-width: 1201px) {
    margin-top: 0px;
    width: 40px;
  }
`;

const MapData: any = {
  temperature: {
    unit: '℃',
    value: '...',
    heading: 'Temperature',
    icon: TemperatureIcon,
  },
  humidity: {
    unit: '%',
    value: '...',
    heading: 'Humidity',
    icon: HumidityIcon,
  },
  weather_condition: {
    unit: '',
    value: '...',
    heading: 'Condition',
    icon: ConditionIcon,
  },
  pressure: {
    unit: 'mb',
    value: '...',
    heading: 'Pressure',
    icon: PressureIcon,
  },
  dew_point: {
    unit: '℃',
    value: '...',
    heading: 'Dew Point',
    icon: DewPointIcon,
  },
  wind_direction: {
    unit: '',
    value: 'WSW',
    heading: 'Wind Direction',
    icon: WindDirectionIcon,
  },
  wind_speed: {
    unit: 'km/h',
    value: '...',
    heading: 'Wind Speed',
    icon: WindSpeedIcon,
  },
  sunrise_time: {
    unit: '24-hour',
    value: '...',
    heading: 'Sunrise',
    icon: SunRiseIcon,
  },
  sunset_time: {
    unit: '24-hour',
    value: '...',
    heading: 'Sunset',
    icon: SunSetIcon,
  },
};

const OneBlock = ({ item, value, unit, bgColor }: any) => {
  return (
    <BlockContainer>
      <Block bgColor={bgColor}>
        <IconContainer >
          <Icon src={MapData[item]['icon']} />
        </IconContainer>
        <TextBox>
          <SubBlockHeading>
            {MapData[item]['heading']}
          </SubBlockHeading>
          <SubBlockText>
            {value}
            <SubBlockUnit>{unit}</SubBlockUnit>
          </SubBlockText>
        </TextBox>
      </Block>
    </BlockContainer>
  );
};

const CardHeader = ({ realtimeData }: any) => {
  return (
    <>
      <Container>
        <OutdoorWeatherHeading >
          OUTDOOR WEATHER
        </OutdoorWeatherHeading>
        <Grid>
          <BlockBody width={20}>
            <BlockContainer style={{ height: '100%', width: '100%', paddingBottom: '20px' }}>
              <MainBlock>
                <MainIcon src={TemperatureIcon} />
                {/* <IconContainer style={{marginRight: '-10px'}}>
                  <MainIcon src={TemperatureIcon} />
                </IconContainer> */}
                <MainTextBox>
                  <MainSubBlockHeading style={{ marginTop: '15px' }}>{'Temperature'}
                  </MainSubBlockHeading>
                  <MainBlockText>
                    {realtimeData['real-time']['temperature']['value'].toFixed(1)}
                    <MainBlockUnit>{'℃'}</MainBlockUnit>
                  </MainBlockText>
                </MainTextBox>
              </MainBlock>
            </BlockContainer>
          </BlockBody>
          <BlockBody width={80}>
            <OneBlock
              item={'humidity'}
              value={realtimeData['real-time']['humidity']['value']}
              unit={MapData['humidity']['unit']}
              bgColor={'#BEE3DB'}
            />
            <OneBlock
              item={'pressure'}
              value={realtimeData['real-time']['pressure']['value'].toFixed(1)}
              unit={MapData['pressure']['unit']}
              bgColor={'#BEE3DB'}
            />
            <OneBlock
              item={'dew_point'}
              value={realtimeData['real-time']['dew_point']['value']}
              unit={MapData['dew_point']['unit']}
              bgColor={'#BEE3DB'}
            />
            <OneBlock
              item={'weather_condition'}
              value={realtimeData['real-time']['weather_condition']['value']}
              unit={MapData['weather_condition']['unit']}
              bgColor={'#BEE3DB'}
            />
            <OneBlock
              item={'sunrise_time'}
              value={realtimeData['sunrise_time']['value']}
              unit={'AM'}
              bgColor={'#DEDBD2'}
            />
            <OneBlock
              item={'sunset_time'}
              value={realtimeData['sunset_time']['value']}
              unit={'PM'}
              bgColor={'#DEDBD2'}
            />
            <OneBlock
              item={'wind_direction'}
              value={realtimeData['real-time']['wind_direction']['value']}
              unit={MapData['wind_direction']['unit']}
              bgColor={'#BEE3DB'}
            />
            <OneBlock
              item={'wind_speed'}
              value={realtimeData['real-time']['wind_speed']['value']}
              unit={MapData['wind_speed']['unit']}
              bgColor={'#BEE3DB'}
            />
          </BlockBody>
        </Grid>
      </Container>
    </>
  );
};

export default CardHeader;
